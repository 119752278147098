body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
}

body::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #0e2841;
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    z-index: 999;
}

// hide updown arrows in input type number
input {

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.tabs-type-1 {
    .nav-item {
        margin: 0;

        .nav-link {
            padding: 8px 16px;
            font-size: 16px;
            font-weight: 600;
            color: #D9D9D9;

            &.active {
                color: $primary;
                border-bottom: 2px solid $primary;
            }
        }
    }
}

.slider-button-index {
    left: 300px;
    top: 48px;

    .btn-index-primary {
        border: 1px solid $border-color;
        background-color: white;
        color: #8D8D8D;
        font-size: 14px;

    }

    .btn-index-primary-active {
        background-color: #0091F7;
        font-size: 14px;
        color: white;
    }
}

.dropdown-toggle-remove {
    .dropdown-toggle {
        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        max-height: 167px;
        overflow: auto;
    }
}

.table-select-remove {
    background-image: none;
}

.hover-table-type {
    .table-hover {
        tbody {
            tr {
                &:hover {
                    background-color: #FFFBFA;
                }

                td {
                    padding: 16px 12px;
                }
            }
        }
    }
}

.group-button-line {
    .btn {
        color: #8D8D8D;
        background-color: #F5F5F5;
        border-color: $border-color;

        &.active {
            background-color: $primary;
            color: white;
            border-color: $primary;
        }
    }
}

.datepicker-staff {
    .react-datepicker__input-container {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        input {
            color: $primary;
            font-weight: 500;
        }

        &::after {
            content: '';
            /* This creates the icon */
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background-image: url('../icons/calander.svg');
            background-size: cover;
        }

        .react-datepicker__close-icon {
            display: none;

        }

        input {
            padding: 0.75rem 1.25rem;
            padding-right: 2rem;
            line-height: 1.3;
            background-color: #ffffff;
            border-radius: 0.375rem;
            box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08);
            border: 1px solid #E7EAF0;
        }
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker {
        width: 100%;
        border: 0;
        box-shadow: 4px 4px 20px 0px #00000014;

        box-shadow: -4px -4px 20px 0px #00000014;

        font-family: 'Inter', sans-serif;

        .react-datepicker__navigation {
            margin-top: 18px;

            &.react-datepicker__navigation--previous {
                left: 14px;
            }

            &.react-datepicker__navigation--next {
                right: 14px
            }
        }

        .react-datepicker__month-container {
            width: 100%;

            .react-datepicker__header {
                background-color: transparent;
                border: 0;
                padding: 24px;

                .react-datepicker__current-month {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 12px;
                }

                .react-datepicker__day-names {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;

                    .react-datepicker__day-name {
                        font-size: 14px;
                        font-weight: 600;
                        width: 32px;
                        height: 32px;
                        margin: 0px;
                        line-height: normal;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .react-datepicker__month {
                margin: 0px;
                padding: 0px 24px 24px 24px;

                .react-datepicker__week {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    margin-bottom: 8px;

                    .react-datepicker__day {
                        font-size: 14px;
                        color: #353535;
                        font-weight: 600;
                        width: 32px;
                        height: 32px;
                        margin: 0px;
                        line-height: normal;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.react-datepicker__day--in-range {
                            background-color: #E6F4FE;
                            color: #0091F7;
                        }

                        &.react-datepicker__day--selected {
                            background-color: #E6F4FE;
                            color: #0091F7;
                        }

                        &.react-datepicker__day--keyboard-selected {
                            background-color: #E6F4FE;
                            color: #0091F7;
                        }

                    }
                }
            }
        }
    }
}


.table-responsive {
    .table {
        tbody {
            tr {
                &:hover {
                    .text-secondary {
                        &.h5 {
                            &.opacity-0 {
                                opacity: 1 !important; // Show LiaPenSolid on hover
                            }
                        }
                    }
                }
            }
        }
    }
}

.small-fullcalendar {
    --fc-border-color: transparent;
    --fc-button-border-color: transparent;
    --fc-button-hover-border-color: transparent;
    --fc-today-bg-color: transparent;
    --fc-event-bg-color: none;
    --fc-event-border-color: none;

    .fc {
        .fc-header-toolbar {
            justify-content: space-around;

            .fc-toolbar-chunk {

                .fc-next-button,
                .fc-prev-button {
                    border-color: transparent;
                    padding: 0;
                    background-color: transparent;
                    font-size: 1rem;
                    color: $dark;
                }

                .fc-toolbar-title {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }

        .fc-view-harness {

            .fc-view {
                .fc-scrollgrid {
                    .fc-scrollgrid-section-header {
                        .fc-col-header-cell-cushion {
                            font-size: 16px;
                            font-weight: 700;
                            display: flex;
                            justify-content: center;
                        }
                    }

                    .fc-scrollgrid-section-body {
                        .fc-day {
                            border: none;

                            &.fc-day-today {
                                .fc-daygrid-day-top {
                                    .fc-daygrid-day-number {
                                        .fc-daygrid-day-top {
                                            background-color: $primary;
                                            color: white;
                                        }
                                    }
                                }
                            }

                            .fc-daygrid-day-top {
                                display: flex;
                                min-height: 1rem;
                                align-items: center;
                                justify-content: center;
                                border-radius: 4px;
                                cursor: pointer;

                                .fc-daygrid-day-number {
                                    display: flex;
                                    justify-content: center;
                                }
                            }

                            .fc-daygrid-day-events {
                                min-height: auto;
                            }

                        }
                    }
                }
            }
        }
    }
}

.overlap-1045 {
    z-index: 1045;
}

.enquiry-type-hot {
    background-color: #d94f23 !important;
}

.enquiry-type-warn {
    background-color: #ffc107 !important;
}

.enquiry-type-cold {
    background-color: $primary !important;
}

.graph-design-custome {
    .domainAndTicks {
        .tick {
            line {
                display: none;
            }

            .tickLabel {
                font-size: 12px !important;
                fill: $dark !important;
            }
        }
    }
}

// date picker open
.rdtPicker {
    // position: relative !important;
    border: 1px solid $gray-300 !important;

    .rdtCounters {
        .rdtCounterSeparator {
            line-height: 57px;
        }

        .rdtCounter {
            height: auto;
            font-size: 12px;

            .rdtBtn {
                background-color: none;
                color: $gray;
                height: 20px;
                width: 40px;
                line-height: normal;
            }

            .rdtCount {
                line-height: normal;
            }
        }
    }
}

.chat-bubble {
    position: absolute;
    // top: 43%;
    // top: 438px;
    // left: 50%;
    // transform: translate(-50%, -50%);
    background-color: #dcf8c6; /* Background color of chat bubble */
    border-radius: 0px 20px 20px 20px; /* Adjust border radius to style chat bubble */
    padding: 10px; /* Adjust padding as needed */
    // width: 276px;
    // max-width: 70%; /* Adjust maximum width of chat bubble */
    margin: -630px 40px;
  }
  
  .chat-content {
    text-align: left;
    color: #000000; /* Text color */
    font-size: 14px;
    word-wrap: break-word;
  }

  .sms-bubble {
    position: absolute;
    // top: 43%;
    // top: 438px;
    // left: 50%;
    // transform: translate(-50%, -50%);
    background-color: #ffffff; /* Background color of chat bubble */
    border-radius: 0px 20px 20px 20px; /* Adjust border radius to style chat bubble */
    padding: 10px; /* Adjust padding as needed */
    width: 276px;
    // max-width: 70%; /* Adjust maximum width of chat bubble */
    margin: -482px 40px;
  }
  
  .sms-content {
    text-align: left;
    color: #000000; /* Text color */
    // font-size: 14px;
    font-weight : bolder;
    word-wrap: break-word;
  }

  .bg-grey {
    background-color: #f6f9fc;
  }