@import "color.scss";

.main-background-color {
  // background-color: #1d1d1d;
  background-color: #121212;
}

.bg-dark-themes-card {
  background-color: #212121 !important;
}

.text-heading-responsive-32 {
  font-size: 32px;
}

.text-paragraph-responsive-18 {
  font-size: 18px;
}

.paragraph-text {
  color: #e7eaec;
}

.bg-orange-gradient {
  background-image: linear-gradient(to right, #f38357, #f59557, #feba59);
}
.bg-Golden-gradient {
  border-radius: 8px;
  background: linear-gradient(
      94deg,
      #bb5d26 -37.82%,
      #ffb239 34.94%,
      #fbd53e 67.08%,
      #fed96e 106.2%
    ),
    #000;
  // background-image: linear-gradient(to right, #f38357, #f59557, #feba59);
}

.orange-1 {
  color: #f38359 !important;
}
.golden-1 {
  color: #fbd53e !important;
}

.dark-tap-bg {
  background-color: #151515 !important;
}

.tabs-wrapper {
  .tabs-container {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
    
    .nav-tabs {
      display: inline-flex;
      flex-wrap: nowrap; 
      overflow-x: auto;
      overflow-y: hidden;
      // justify-content: space-around;

      .nav-item {
        flex: 0 0 auto;
      }
    }
  }

  .tab-content {
    /* Ensure the content is not affected by the horizontal scroll */
    white-space: normal;
  }
}


.tabs-type-dark {
  // display: flex;
  // display: inline-flex; //new
  // overflow-x: auto;
  // white-space: nowrap;
  // flex-wrap: nowrap; // new
  // justify-content: space-around;
  // border: 1px solid #1d1d1d !important;
  border: 1px solid #121212 !important;

  .nav-item {
    margin: 0px !important;
    width: auto;
    flex: 0 0 auto; //new

    .nav-link {
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 600;
      color: #b3aeae;
      width: auto;

      &.active {
        // color: #f3a35c;
        // border-bottom: 2px solid #f3a35c;
        border-bottom: 2px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(
          97deg,
          #d4a647 4.12%,
          #fbefb0 53.03%,
          #e8ba4e 91.69%
        );
        background: linear-gradient(
          97deg,
          #d4a647 4.12%,
          #fbefb0 53.03%,
          #e8ba4e 91.69%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.control {
  border: 1px solid white !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: #e1e1e1 !important;
}

.datepicker-staff-dark {
  .date-icon-add {
    .react-datepicker__input-container {
      &::after {
        content: "";
        /* This creates the icon */
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        // background-image: url('../icons/calander.svg');
        background-image: url("../icons/select-date.svg");
        background-size: cover;
      }
    }
  }

  .time-icon-add {
    .react-datepicker__input-container {
      &::after {
        content: "";
        /* This creates the icon */
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background-image: url("../icons/select-time.svg");
        background-size: cover;
      }
    }
  }

  .react-datepicker__input-container {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    input {
      width: 100%;
      font-weight: 400;
      color: white;
      padding: 0.75rem 1.25rem;
      padding-right: 2rem;
      line-height: 1.3;
      background-color: transparent;
      border-radius: 0.375rem;
      box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08);
      border: 1px solid #e7eaf0;
    }

    .react-datepicker__close-icon {
      display: none;
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    background-color: #212121 !important;

    .react-datepicker__navigation {
      .react-datepicker__navigation-icon {
        &::before {
          border-color: #a8a8a8;
        }
      }
    }

    .react-datepicker__time-container {
      .react-datepicker__header {
        background-color: #212121;

        .react-datepicker-time__header {
          color: #a8a8a8;
        }
      }

      .react-datepicker__time {
        background-color: #212121;

        .react-datepicker__time-box {
          .react-datepicker__time-list {
            &::-webkit-scrollbar {
              width: 10px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #353535;
            }

            &::-webkit-scrollbar-thumb {
              background: #f38359 !important;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }

            .react-datepicker__time-list-item {
              color: #a8a8a8;

              &:hover {
                background-color: #151515;
              }

              &.react-datepicker__time-list-item--selected {
                background-color: #f38359 !important;
                color: white;
              }
            }
          }
        }
      }
    }

    .react-datepicker__month-container {
      width: 100%;

      .react-datepicker__header {
        background-color: #212121;

        .react-datepicker__current-month {
          color: #a8a8a8;
        }

        .react-datepicker__day-names {
          .react-datepicker__day-name {
            color: #a8a8a8;
          }
        }
      }

      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            color: #a8a8a8;

            &.react-datepicker__day--selected {
              background-color: #0091f7;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .text-heading-responsive-32 {
    font-size: 18px;
  }

  .text-paragraph-responsive-18 {
    font-size: 12px;
  }
}

.refer-earn-background {
  background-image: url("../images/refer-earn-background-img.svg");
  // background-size: cover;
  height: 500px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.refer-earn-text {
  /** TEXT GRADIENT */
  color: #d4a647;
  background-image: -webkit-linear-gradient(
    0deg,
    #d4a647 18%,
    #fbefb0 33%,
    #e8ba4e 87%
  );
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}
.text-gradient {
  background: linear-gradient(
    97deg,
    #d4a647 4.12%,
    #fbefb0 53.03%,
    #e8ba4e 91.69%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.refer-earn-sec {
  // background-color: #0e0e0e;
  // height: 100vh;
  background-image: url("../images/backgroundPattern.svg");
  background-repeat: repeat-x;
  background-position: top;
  background-size: auto;
  h3 {
    font-size: 28px;
    background: linear-gradient(
      97deg,
      #d4a647 4.12%,
      #fbefb0 53.03%,
      #e8ba4e 91.69%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 40px rgba(212, 166, 71, 0.6), 0 0 60px rgba(6, 4, 0, 0.6),
    0 0 80px rgba(6, 4, 0, 0.6), 0 0 100px rgba(6, 4, 0, 0.6) !important;
    // text-shadow: 2px 2px 4px rgba(212, 166, 71, 0.5) !important;
  }
  .how-this-works {
    h6 {
      color: #dcb568;
      &::before {
        content: "";
        content: "";
        width: 28px;
        background: linear-gradient(
          90deg,
          rgb(255, 255, 255) 0%,
          rgb(215, 172, 79) 50%,
          rgb(234, 191, 87) 100%
        );
        display: inline-block;
        height: 2px;
        position: absolute;
        left: -14px;
        transform: translate(-50%, -50%);
        top: 50%;
      }
      &::after {
        content: "";
        width: 28px;
        background: linear-gradient(
          90deg,
          rgb(255, 255, 255) 0%,
          rgb(215, 172, 79) 50%,
          rgb(234, 191, 87) 100%
        );
        display: inline-block;
        height: 2px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        right: -40px;
      }
    }
  }
  .your-referral-code {
    .your-referral-code-input {
      border: 1px dashed #525252;
      background-color: #000000;
      .your-referral-code-text {
        background: linear-gradient(
          93.63deg,
          #bb5d26 -37.82%,
          #ffb239 34.94%,
          #fbd53e 67.08%,
          #fed96e 106.2%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .loyalty-point {
      background-color: #000000;
      border: 1px solid #515151;
      background: #000;
      box-shadow: 0px 4px 20px 0px rgba(253, 215, 87, 0.26);
      .share-text {
        background: linear-gradient(
          93.63deg,
          #bb5d26 -37.82%,
          #ffb239 34.94%,
          #fbd53e 67.08%,
          #fed96e 106.2%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.refer-now {
  border: 1px solid transparent;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    96.42deg,
    rgba(215, 172, 79, 0.5) 0%,
    rgba(255, 243, 214, 0.5) 100%
  );
  background-color: #000;
  border-radius: 8px; /* Adjust the radius as needed */
  overflow: hidden; /* Ensures inner content respects the radius */

  p {
    color: #808080;
  }
}

// .background-svg {
//   background-image: url("../images/backgroundPattern.svg"); // Replace with the path to your SVG file
//   background-repeat: repeat-x;
//   background-position: top;
//   background-size: auto;
// }
.background-svg {
  // position: relative;
  background-image: url("../images/backgroundPatternWithoutGradient.svg");
  background-repeat: repeat-x;
  background-position: top;
  background-size: auto;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 271px; /* Replace this with the height of your background image */
    background: linear-gradient(to bottom, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.4) 33%, rgba(18, 18, 18, 1) 66% , rgba(18, 18, 18, 1) 100%);
    pointer-events: none; /* Ensures the gradient overlay doesn't interfere with interaction */
  }
}

.border-gold {
  position: relative;
  border-radius: 12px !important; /* Make sure the border radius is applied */
  background-color: #090909 !important;

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: inherit;
    padding: 1px; /* Adjust this value to match the border width */
    background: linear-gradient(
      93.63deg,
      #bb5d26 -37.82%,
      #ffb239 34.94%,
      #fbd53e 67.08%,
      #fed96e 106.2%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: inherit;
  }

  .card-body {
    position: relative;
    background-color: #090909 !important;
    border-radius: inherit; /* Make sure the border radius is applied */
    overflow: hidden;
    z-index: 1;
  }
}

.glowing-card {
  // box-shadow: 0 0 15px rgba(6, 4, 0, 0.5),
  //             0 0 25px rgba(6, 4, 0, 0.5),
  //             0 0 35px rgba(6, 4, 0, 0.5),
  //             0 0 45px rgba(6, 4, 0, 0.5);
  box-shadow: 0 0 40px rgba(6, 4, 0, 0.6), 0 0 60px rgba(6, 4, 0, 0.6),
    0 0 80px rgba(6, 4, 0, 0.6), 0 0 100px rgba(6, 4, 0, 0.6) !important;
  transition: box-shadow 0.3s ease-in-out;
}

.glowing-card:hover {
  // box-shadow: 0 0 20px rgba(255, 182, 73, 0.6),
  box-shadow: 0 0 40px rgba(6, 4, 0, 0.6), 0 0 60px rgba(6, 4, 0, 0.6),
    0 0 80px rgba(6, 4, 0, 0.6), 0 0 100px rgba(6, 4, 0, 0.6);
}

.profile-section-shadow {
  box-shadow: 0 0 40px rgba(6, 4, 0, 1), 0 0 60px rgba(6, 4, 0, 1),
    0 0 80px rgba(6, 4, 0, 1), 0 0 100px rgba(6, 4, 0, 1) !important;
  transition: box-shadow 0.3s ease-in-out;
}

.light-border {
  border-color: #292929 !important;
}

.golden-radio {
  &.form-check-input {
    background-color: transparent;
    border-color: #fbd53e;

    &:checked {
      background-color: #fbd53e;
    }
  }
}